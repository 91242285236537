export class TemplatePatientResult {
  _id: string;
  reportTemplateType: string;
  reportBody: object;
  templateCode: string;
  templateTitle: string;
  reportOrder: number;
  endOfPage: any[];
  endOfReport: any[];
  departmentHeader: {
    nameCSS: string;
  };
  panelHeader: {
    descriptionCSS: string;
    methodDescriptionCSS: string;
  };
  testHeader: {
    descriptionCSS: string;
    methodDescriptionCSS: string;
  };
  patientResultList: {
    fieldsTextCSS: string;
  };
  testFooter: {
    interpretationCSS: string;
  };
  panelFooter: {
    interpretationCSS: string;
  };
  departmentFooter: {
    imageCSS: string;
  };
  companyCode: string; // Added companyCode
  environment: string; // Added environment
  status: string;
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    reportTemplateType: 'string',
    reportBody: 'object',
    templateCode: 'string',
    templateTitle: 'string',
    reportOrder: 'number',
    endOfPage: 'Array',
    endOfReport: 'Array',
    departmentHeader: 'object',
    panelHeader: 'object',
    testHeader: 'object',
    patientResultList: 'object',
    testFooter: 'object',
    panelFooter: 'object',
    departmentFooter: 'object',
    companyCode: 'string', // Added companyCode
    environment: 'string', // Added environment
    status: 'string',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.reportTemplateType = rawData.reportTemplateType;
    this.reportBody = rawData.reportBody;
    this.templateCode = rawData.templateCode;
    this.templateTitle = rawData.templateName;
    this.reportOrder = rawData.reportOrder;
    this.endOfPage = rawData.endOfPage;
    this.endOfReport = rawData.endOfReport;
    this.departmentHeader = rawData.departmentHeader;
    this.panelHeader = rawData.panelHeader;
    this.testHeader = rawData.testHeader;
    this.patientResultList = rawData.patientResultList;
    this.testFooter = rawData.testFooter;
    this.panelFooter = rawData.panelFooter;
    this.departmentFooter = rawData.departmentFooter;
    this.documentType = rawData.documentType;
    this.companyCode = rawData.companyCode; // Added companyCode
    this.environment = rawData.environment; // Added environment
    this.status = rawData.status;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedItemsTemplatePatientResult {
  endOfPage: any[];
  endOfReport: any[];
  constructor(rawData: { [key in string]: any }) {
    this.endOfPage = rawData.endOfPage;
    this.endOfReport = rawData.endOfReport;
  }
}
