import { gql } from '@apollo/client';

export const LIST = gql`
  mutation ($input: FieldWiseControlInput!) {
    fieldWiseControl(input: $input) {
      paginatorInfo {
        count
      }
      success
      message
      data {
        _id
        controlCode
        controlName
        moduleName
        role
        event
        fields
        version
        companyCode
        status
        environment
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE = gql`
  mutation ($input: CreateFieldWiseControlInput!) {
    createFieldWiseControl(input: $input) {
      success
      message
    }
  }
`;

export const REMOVE_RECORDS = gql`
  mutation ($input: FieldWiseControlRemoveInput!) {
    removeFieldWiseControl(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE = gql`
  mutation ($input: UpdateFieldWiseControlInput!) {
    updateFieldWiseControl(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_IMAGE = gql`
  mutation ($input: UpdateBannerInput!) {
    updateBannerImage(input: $input) {
      success
      message
    }
  }
`;

export const FILTER = gql`
  mutation ($input: FieldWiseControlInput!) {
    filterFieldWiseControl(input: $input) {
      paginatorInfo {
        count
      }
      success
      message
      data {
        _id
        controlCode
        controlName
        moduleName
        role
        event
        fields
        version
        companyCode
        status
        environment
        createdAt
        updatedAt
      }
    }
  }
`;

export const FILTER_BY_FIELDS = gql`
  mutation ($input: FieldWiseControlInput!) {
    filterFieldWiseControl(input: $input) {
      paginatorInfo {
        count
      }
      success
      message
      data {
        _id
        controlCode
        controlName
        moduleName
        role
        event
        fields
        version
        companyCode
        status
        environment
        createdAt
        updatedAt
      }
    }
  }
`;

export const FIND_BY_FIELDS = gql`
  mutation ($input: FieldWiseControlInput!) {
    findByFieldsFieldWiseControl(input: $input) {
      success
      message
      data {
        _id
        controlCode
        controlName
        moduleName
        role
        event
        fields
        version
        companyCode
        status
        environment
        createdAt
        updatedAt
      }
    }
  }
`;
