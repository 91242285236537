import React from 'react';

interface DoctorInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DoctorInfoModal: React.FC<DoctorInfoModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div>
      {/* Modal Background Overlay */}
      <div
        className='fixed inset-0 bg-[#00000024] transition-opacity'
        style={{ zIndex: 9998 }}
      ></div>

      {/* Modal Container */}
      <div className='fixed z-9999 inset-0  overflow-y-auto'>
        <div className='flex items-center justify-center min-h-screen p-4'>
          {/* Modal Box */}
          <div className='bg-white rounded-lg shadow-xl w-full max-w-md p-4 relative'>
            {/* Modal Header */}
            <div className='flex justify-between items-center pb-2 border-b border-gray-200'>
              <h2 className='text-xl font-semibold text-gray-800'>
                Doctor Information
              </h2>
              <button
                className='text-gray-400 hover:text-gray-600'
                onClick={onClose}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className='mt-2 space-y-1'>
              <div className='text-sm text-gray-700'>
                <strong className='font-semibold'>Doctor Code: </strong>{' '}
                <span>DC1234</span>
              </div>
              <div className='text-sm text-gray-700'>
                <strong className='font-semibold'>Doctor Name: </strong>{' '}
                <span>Dr. John Doe</span>
              </div>
              <div className='text-sm text-gray-700'>
                <strong className='font-semibold'>Speciality: </strong>{' '}
                <span>Cardiology</span>
              </div>
              <div className='text-sm text-gray-700'>
                <strong className='font-semibold'>Contact No: </strong>{' '}
                <span>+123 456 7890</span>
              </div>
              <div className='text-sm text-gray-700'>
                <strong className='font-semibold'>Email: </strong>{' '}
                <span>johndoe@example.com</span>
              </div>
            </div>

            {/* Modal Footer */}
            <div className='mt-4 flex justify-end'>
              <button
                className='px-3 py-1 bg-gray-500 text-white text-sm rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorInfoModal;
