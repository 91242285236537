export class FieldWiseControl {
  controlCode: string;
  controlName: string;
  moduleName: Array<string>;
  role: Array<string>;
  event: string;
  fields: Array<{
    screen: any;
    field: string;
    fieldValue: string;
    label: string;
    id: string;
    order: number;
  }>;
  version: number;
  companyCode: string;
  status: string;
  environment: string;
  createdAt: Date;
  updatedAt: Date;
  constructor(rawData: { [key in string]: any }) {
    this.controlCode = rawData.controlCode;
    this.controlName = rawData.controlName;
    this.moduleName = rawData.moduleName;
    this.role = rawData.role;
    this.event = rawData.event;
    this.fields = rawData.fields;
    this.version = rawData.version;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.createdAt = rawData.createdAt;
    this.updatedAt = rawData.updatedAt;
  }
}

export class SelectedItems {
  role: any[];
  constructor(rawData: { [key in string]: any }) {
    this.role = rawData.role;
  }
}
