import React, { useRef } from 'react';
import { PdfView, PdfSmall } from '@components';
import { StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  tableCol: {
    flexDirection: 'column',
    textAlign: 'center',
  },
});

interface PdfTemp0001FieldsProps {
  data: any;
}

export const PdfTemp0001Fields = ({ data }: PdfTemp0001FieldsProps) => {
  const fieldsCSS = useRef<any>({});
  const mainBoxCSS = useRef<any>({});
  if (data.fields?.fieldsCSS) {
    try {
      fieldsCSS.current = eval('({' + data.fields?.fieldsCSS + '})');
    } catch (e) {
      fieldsCSS.current = {};
    }
  }
  if (data.fields?.mainBoxCSS) {
    try {
      mainBoxCSS.current = eval('({' + data.fields?.mainBoxCSS + '})');
    } catch (e) {
      mainBoxCSS.current = {};
    }
  }

  const grid = data.fields?.grid || 2;
  const fields: Array<any> = [];
  for (let i = 0; i < data.fields?.fieldsList?.length; i += grid) {
    fields.push(data.fields?.fieldsList?.slice(i, i + grid));
  }

  return (
    <PdfView
      mh={10}
      p={0}
      style={{
        borderTop: '1px solid #000000',
        borderBottom: '1px solid #000000',
        paddingTop: '2px',
        paddingBottom: '2px',
        ...mainBoxCSS.current,
      }}
    >
      {fields?.map((e, i) => (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: i != 0 ? '4px' : '0px',
            marginBottom: fields?.length - 1 == i ? '4px' : '0px',
          }}
          key={i}
        >
          {e?.map((item, index) => (
            <View style={[styles.tableCol]} key={index}>
              <PdfSmall style={{ ...fieldsCSS.current }}>
                {`${item?.label} : ${item?.field?.split(' - ')[0]}`}
              </PdfSmall>
            </View>
          ))}
        </View>
      ))}
    </PdfView>
  );
};
