import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Buttons,
  Form,
  List,
  Grid,
  Svg,
  Toast,
  ModalConfirm,
  MainPageHeading,
  StaticInputTable,
  ImportFile,
  Icons,
  ManualImportTabs,
} from '@/library/components';
import { lookupItems, lookupValue } from '@/library/utils';
import { useForm, Controller } from 'react-hook-form';
import { FieldWiseControlHoc } from '../hoc';
import { useStores } from '@/stores';
import _ from 'lodash';
import { RouterFlow } from '@/flows';
import * as XLSX from 'xlsx';
import {
  MultiSelect,
  AutocompleteGroupBy,
  AutoCompleteFilterMultipleSelectMultipleFieldsDisplay,
} from 'react-restyle-components';
import { FieldWiseControlList } from '../components';
// utils
import { getFields, getRouterList } from '@/core-utils';
import { toJS } from 'mobx';
const router = getRouterList();

export const FieldWiseControl = FieldWiseControlHoc(
  observer(() => {
    const {
      loginStore,
      roleStore,
      fieldWiseControlStore,
      routerStore,
      companyStore,
    } = useStores();
    const {
      control,
      formState: { errors },
      handleSubmit,
      setValue,
      reset,
    } = useForm();

    useEffect(() => {
      // Default value initialization
      setValue('status', fieldWiseControlStore.fieldWiseControl?.status);
      setValue(
        'moduleName',
        fieldWiseControlStore.fieldWiseControl?.moduleName,
      );
      setValue('role', fieldWiseControlStore.fieldWiseControl?.role);
      setValue('event', fieldWiseControlStore.fieldWiseControl?.event);
      setValue('version', fieldWiseControlStore.fieldWiseControl.version);
      setValue('createdAt', fieldWiseControlStore.fieldWiseControl.createdAt);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldWiseControlStore.fieldWiseControl]);

    const [modalConfirm, setModalConfirm] = useState<any>();
    const [isHideAddView, setIsHideAddView] = useState<boolean>(true);
    const [isImport, setIsImport] = useState<boolean>(false);
    const [arrImportRecords, setArrImportRecords] = useState<Array<any>>([]);
    const [isExistsRecord, setIsExistsRecord] = useState(false);
    const [fieldsInput, setFieldsInput] = useState<
      Partial<{
        screen: any;
        field: string;
        fieldValue: string;
        label: string;
        id: string;
        order: number;
      }>
    >({});
    const [moduleList, setModuleList] = useState([]);
    const [fieldsList, setFieldsList] = useState<any>([]);

    useEffect(() => {
      companyStore.companyService
        .findByFields({
          input: { filter: { code: loginStore.login.companyCode } },
        })
        .then(res => {
          if (res.findByFieldsCompany.success) {
            setModuleList(res.findByFieldsCompany?.data[0]?.module || []);
          } else {
            Toast.error({
              message: '😔 Module list not found',
            });
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitFieldWiseControl = async () => {
      if (!isExistsRecord) {
        await fieldWiseControlStore.fieldWiseControlService
          .create({
            input: isImport
              ? { isImport, arrImportRecords }
              : { isImport, ...fieldWiseControlStore.fieldWiseControl },
          })
          .then(res => {
            if (res.createFieldWiseControl.success) {
              Toast.success({
                message: `😊 ${res.createFieldWiseControl.message}`,
              });
              setIsHideAddView(true);
              reset();
              setArrImportRecords([]);
              setIsImport(false);
              fieldWiseControlStore.fieldWiseControlService.list();
            }
          });
      } else {
        Toast.error({
          message: '😔 Duplicate record found',
        });
      }
    };

    const handleFileUpload = (file: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', (evt: any) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: true });
        const list = data.map((item: any) => {
          return {
            module: [],
            role: [],
            screen: '',
            event: item?.Event,
            fields: [],
            dateCreation: new Date(),
            version: item?.Version,
            environment: item?.Environment,
            companyCode: item['Company Code'],
            status: 'D',
          };
        });
        setArrImportRecords(list);
      });
      reader.readAsBinaryString(file);
    };

    const tableView = useMemo(() => {
      return (
        <FieldWiseControlList
          data={fieldWiseControlStore.listFieldWiseControl || []}
          totalSize={fieldWiseControlStore.listFieldWiseControlCount}
          extraData={{
            lookupItems: routerStore.lookupItems,
            moduleList,
            router,
          }}
          isView={RouterFlow.checkPermission(
            routerStore.userPermission,
            'View',
          )}
          isDelete={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Delete',
          )}
          isUpdate={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Update',
          )}
          isExport={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Export',
          )}
          isVersionUpgrade={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Version Upgrade',
          )}
          isDuplicate={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Duplicate',
          )}
          onDelete={selectedItem => setModalConfirm(selectedItem)}
          onSelectedRow={rows => {
            setModalConfirm({
              show: true,
              type: 'delete',
              id: rows,
              title: 'Are you sure?',
              body: 'Do you want to delete selected record?',
            });
          }}
          onUpdateFields={(fields: any, id: string) => {
            setModalConfirm({
              show: true,
              type: 'updateFields',
              data: { fields, id },
              title: 'Are you sure?',
              body: 'Do you want to update this record?',
            });
          }}
          onVersionUpgrade={item => {
            setModalConfirm({
              show: true,
              type: 'versionUpgrade',
              data: item,
              title: 'Are you sure?',
              body: 'Do you want to upgrade version for this record?',
            });
          }}
          onDuplicate={item => {
            setModalConfirm({
              show: true,
              type: 'duplicate',
              data: item,
              title: 'Are you sure?',
              body: 'Do you want to duplicate this record?',
            });
          }}
          onPageSizeChange={(page, limit) => {
            // libraryStore.fetchLibrary(page, limit);
            global.filter = { mode: 'pagination', page, limit };
          }}
          onFilter={(type, filter, page, limit) => {
            fieldWiseControlStore.fieldWiseControlService.filter({
              input: { type, filter, page, limit },
            });
          }}
          onApproval={async records => {
            const isExists = await checkExistsRecords({
              ...records,
              status: 'A',
            });
            if (typeof isExists == 'boolean' && !isExists) {
              setModalConfirm({
                show: true,
                type: 'updateFields',
                data: { fields: { status: 'A' }, id: records._id },
                title: 'Are you sure?',
                body: 'Do you want to update this record?',
              });
            }
          }}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldWiseControlStore.listFieldWiseControl, moduleList]);

    const checkExistsRecords = async (
      fields: any = fieldWiseControlStore.fieldWiseControl,
      isSingleCheck = false,
    ) => {
      const requiredFields = [
        'controlCode',
        'controlName',
        'moduleName',
        'role',
        'event',
        'status',
      ];
      const isEmpty = requiredFields.find(item => {
        if (_.isEmpty({ ...fields }[item])) return item;
      });
      if (isEmpty && !isSingleCheck) {
        return Toast.error({
          message: `😔 Required ${isEmpty} value missing. Please enter correct value`,
        });
      }
      if (!isEmpty)
        return fieldWiseControlStore.fieldWiseControlService
          .findByFields({
            input: {
              filter: isSingleCheck
                ? { ...fields }
                : {
                    ..._.pick({ ...fields }, requiredFields),
                  },
            },
          })
          .then(res => {
            if (res.findByFieldsFieldWiseControl?.success) {
              setIsExistsRecord(true);
              Toast.error({
                message: '😔 Already some record exists.',
              });
              return true;
            } else {
              setIsExistsRecord(false);
              return false;
            }
          });
    };

    return (
      <>
        <MainPageHeading
          title={routerStore.selectedComponents?.title || ''}
          store={loginStore}
        />
        <div
          className='flex justify-end'
          style={{
            position: 'fixed',
            right: '30px',
            top: '135px',
            zIndex: 9999,
          }}
        >
          {RouterFlow.checkPermission(routerStore.userPermission, 'Add') && (
            <Buttons.ButtonCircleAddRemoveBottom
              show={isHideAddView}
              onClick={() => setIsHideAddView(!isHideAddView)}
            />
          )}
        </div>

        <div className=' mx-auto  flex-wrap'>
          <div
            className={
              'p-2 rounded-lg shadow-xl overflow-auto ' +
              (isHideAddView ? 'hidden' : 'shown')
            }
          >
            <ManualImportTabs
              isImport={isImport}
              isImportDisable={
                !RouterFlow.checkPermission(
                  toJS(routerStore.userPermission),
                  'Import',
                )
              }
              onClick={flag => {
                setIsImport(flag);
              }}
            />
            {!isImport ? (
              <Grid cols={2}>
                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Control Code'
                        placeholder='Control Code'
                        hasError={!!errors.controlCode}
                        value={value}
                        onChange={controlCode => {
                          onChange(controlCode);
                          fieldWiseControlStore.updateFieldWiseControl({
                            ...fieldWiseControlStore.fieldWiseControl,
                            controlCode,
                          });
                        }}
                        onBlur={controlCode => {
                          if (controlCode)
                            checkExistsRecords(
                              {
                                controlCode,
                                controlName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlName,
                                moduleName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .moduleName,
                                role: fieldWiseControlStore.fieldWiseControl
                                  .role,
                                event:
                                  fieldWiseControlStore.fieldWiseControl.event,
                                status:
                                  fieldWiseControlStore.fieldWiseControl.status,
                              },
                              true,
                            );
                        }}
                      />
                    )}
                    name='controlCode'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Control Name'
                        placeholder='Control Name'
                        hasError={!!errors.controlName}
                        value={value}
                        onChange={controlName => {
                          onChange(controlName);
                          fieldWiseControlStore.updateFieldWiseControl({
                            ...fieldWiseControlStore.fieldWiseControl,
                            controlName,
                          });
                        }}
                        onBlur={controlName => {
                          if (controlName)
                            checkExistsRecords(
                              {
                                controlCode:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlCode,
                                controlName,
                                moduleName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .moduleName,
                                role: fieldWiseControlStore.fieldWiseControl
                                  .role,
                                event:
                                  fieldWiseControlStore.fieldWiseControl.event,
                                status:
                                  fieldWiseControlStore.fieldWiseControl.status,
                              },
                              true,
                            );
                        }}
                      />
                    )}
                    name='controlName'
                    rules={{ required: true }}
                    defaultValue=''
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Module Name'
                        hasError={!!errors.moduleName}
                      >
                        <MultiSelect
                          selectedItems={value}
                          hasError={!!errors.moduleName}
                          options={moduleList}
                          onSelect={moduleName => {
                            onChange(moduleName);
                            fieldWiseControlStore.updateFieldWiseControl({
                              ...fieldWiseControlStore.fieldWiseControl,
                              moduleName,
                            });
                            checkExistsRecords(
                              {
                                controlCode:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlCode,
                                controlName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlName,
                                moduleName,
                                role: fieldWiseControlStore.fieldWiseControl
                                  .role,
                                event:
                                  fieldWiseControlStore.fieldWiseControl.event,
                                status:
                                  fieldWiseControlStore.fieldWiseControl.status,
                              },
                              true,
                            );
                          }}
                        />
                      </Form.InputWrapper>
                    )}
                    name='moduleName'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Role'
                        hasError={!!errors.roleInputValue}
                      >
                        <AutoCompleteFilterMultipleSelectMultipleFieldsDisplay
                          loader={false}
                          placeholder='Search by code'
                          data={{
                            list:
                              roleStore.listRole?.filter(
                                item => item.status == 'A',
                              ) || [],
                            selected: fieldWiseControlStore.selectedItems.role,
                            displayKey: ['code', 'description'],
                          }}
                          hasError={!!errors.roleInputValue}
                          onUpdate={item => {
                            const role =
                              fieldWiseControlStore.selectedItems.role
                                ?.map(item => item?.code)
                                ?.filter(item => item);
                            onChange(role);
                            fieldWiseControlStore.updateFieldWiseControl({
                              ...fieldWiseControlStore.fieldWiseControl,
                              role,
                            });
                            checkExistsRecords(
                              {
                                controlCode:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlCode,
                                controlName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlName,
                                moduleName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .moduleName,
                                role,
                                event:
                                  fieldWiseControlStore.fieldWiseControl.event,
                                status:
                                  fieldWiseControlStore.fieldWiseControl.status,
                              },
                              true,
                            );
                          }}
                          onFilter={(value: string) => {
                            roleStore.RoleService.filterByFields({
                              input: {
                                filter: {
                                  fields: ['code', 'description'],
                                  srText: value,
                                },
                                page: 0,
                                limit: 10,
                              },
                            });
                          }}
                          onSelect={item => {
                            let role =
                              fieldWiseControlStore.selectedItems?.role;
                            if (!item.selected) {
                              if (role && role.length > 0) {
                                role.push(item);
                              } else role = [item];
                            } else {
                              role = role?.filter(items => {
                                return items._id !== item._id;
                              });
                            }
                            onChange(role);
                            fieldWiseControlStore.updateSelectedItems({
                              ...fieldWiseControlStore.selectedItems,
                              role,
                            });
                          }}
                        />
                      </Form.InputWrapper>
                    )}
                    name='roleInputValue'
                    rules={{ required: true }}
                    defaultValue={roleStore.listRole}
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Event'
                        hasError={!!errors.event}
                      >
                        <select
                          value={value}
                          className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                            errors.event ? 'border-red  ' : 'border-gray-300'
                          } rounded-md`}
                          onChange={e => {
                            const event = e.target.value;
                            onChange(event);
                            fieldWiseControlStore.updateFieldWiseControl({
                              ...fieldWiseControlStore.fieldWiseControl,
                              event,
                            });
                            checkExistsRecords(
                              {
                                controlCode:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlCode,
                                controlName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .controlName,
                                moduleName:
                                  fieldWiseControlStore.fieldWiseControl
                                    .moduleName,
                                role: fieldWiseControlStore.fieldWiseControl
                                  .role,
                                event,
                                status:
                                  fieldWiseControlStore.fieldWiseControl.status,
                              },
                              true,
                            );
                          }}
                        >
                          <option>Select</option>
                          {lookupItems(routerStore.lookupItems, 'EVENT').map(
                            (item: any, index: number) => (
                              <option key={index} value={item.code}>
                                {lookupValue(item)}
                              </option>
                            ),
                          )}
                        </select>
                      </Form.InputWrapper>
                    )}
                    name='event'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                  <Form.InputWrapper label='Fields'>
                    <Grid cols={3}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.InputWrapper
                            hasError={!!errors.screen}
                            label=''
                          >
                            <AutocompleteGroupBy
                              hasError={!!errors.screen}
                              data={router}
                              displayValue={
                                fieldsInput?.screen?.children?.name || ''
                              }
                              onChange={async (item: any, children: any) => {
                                const documentName = {
                                  name: item.name,
                                  title: item.title,
                                  path: item.path,
                                  children,
                                };
                                onChange(documentName.children.name);
                                setFieldsInput({
                                  ...fieldsInput,
                                  screen: documentName,
                                });
                                // fetch fields list
                                const list = getFields(
                                  `${documentName.name}-${documentName.children.name}`,
                                );
                                setFieldsList(Object.entries(list));
                              }}
                            />
                          </Form.InputWrapper>
                        )}
                        name='screen'
                        rules={{ required: true }}
                        defaultValue={fieldsInput?.screen?.children?.name}
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <select
                              value={fieldsInput?.field || ''}
                              className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                                errors.field
                                  ? 'border-red  '
                                  : 'border-gray-300'
                              } rounded-md`}
                              onChange={e => {
                                const field = e.target.value;
                                onChange(field);
                                setFieldsInput({
                                  ...fieldsInput,
                                  field,
                                });
                              }}
                            >
                              <option>Select</option>
                              {fieldsList?.map((item: any, index: number) => (
                                <option key={index} value={item.join(' - ')}>
                                  {item.join(' - ')}
                                </option>
                              ))}
                            </select>
                          </>
                        )}
                        name='field'
                        rules={{ required: false }}
                        defaultValue={fieldsInput?.field}
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Input
                            placeholder='Field Value'
                            value={fieldsInput?.fieldValue || ''}
                            onChange={fieldValue => {
                              setFieldsInput({
                                ...fieldsInput,
                                fieldValue,
                              });
                            }}
                          />
                        )}
                        name='value'
                        rules={{ required: false }}
                        defaultValue=''
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Input
                            placeholder='Label'
                            value={fieldsInput?.label || ''}
                            onChange={label => {
                              setFieldsInput({
                                ...fieldsInput,
                                label,
                              });
                            }}
                          />
                        )}
                        name='value'
                        rules={{ required: false }}
                        defaultValue=''
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Input
                            placeholder='Id'
                            value={fieldsInput?.id || ''}
                            onChange={id => {
                              setFieldsInput({
                                ...fieldsInput,
                                id,
                              });
                            }}
                          />
                        )}
                        name='value'
                        rules={{ required: false }}
                        defaultValue=''
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Input
                            placeholder='Order'
                            value={fieldsInput?.order?.toString() || ''}
                            onChange={order => {
                              setFieldsInput({
                                ...fieldsInput,
                                order: Number.parseInt(order),
                              });
                            }}
                          />
                        )}
                        name='value'
                        rules={{ required: false }}
                        defaultValue=''
                      />
                      <Buttons.Button
                        size='medium'
                        type='solid'
                        onClick={() => {
                          let arrValue =
                            fieldWiseControlStore.fieldWiseControl.fields || [];
                          if (Object.keys(fieldsInput)?.length == 0)
                            return alert('Please enter correct values.');
                          arrValue?.length > 0
                            ? arrValue.push(fieldsInput as any)
                            : (arrValue = [fieldsInput as any]);
                          fieldWiseControlStore.updateFieldWiseControl({
                            ...fieldWiseControlStore.fieldWiseControl,
                            fields: arrValue,
                          });
                          setFieldsInput({});
                        }}
                      >
                        <Icons.EvaIcon icon='plus-circle-outline' />
                        {'Add'}
                      </Buttons.Button>
                    </Grid>
                    <List space={2} direction='row' justify='center'>
                      <div className='mt-2'>
                        {fieldWiseControlStore.fieldWiseControl.fields?.map(
                          (item, index) => (
                            <div className='mb-2' key={index}>
                              <Buttons.Button
                                size='medium'
                                type='solid'
                                icon={Svg.Remove}
                                onClick={() => {
                                  const firstArr =
                                    fieldWiseControlStore.fieldWiseControl.fields?.slice(
                                      0,
                                      index,
                                    ) || [];
                                  const secondArr =
                                    fieldWiseControlStore.fieldWiseControl.fields?.slice(
                                      index + 1,
                                    ) || [];
                                  const finalArray = [
                                    ...firstArr,
                                    ...secondArr,
                                  ];
                                  fieldWiseControlStore.updateFieldWiseControl({
                                    ...fieldWiseControlStore.fieldWiseControl,
                                    fields: finalArray,
                                  });
                                }}
                              >
                                {`${item?.screen?.children?.name} - ${item?.field} - ${item?.fieldValue} - ${item?.label}- ${item?.id} - ${item?.order}`}
                              </Buttons.Button>
                            </div>
                          ),
                        )}
                      </div>
                    </List>
                  </Form.InputWrapper>
                </List>
                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Status'
                        hasError={!!errors.status}
                      >
                        <select
                          value={value}
                          className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                            errors.status ? 'border-red  ' : 'border-gray-300'
                          } rounded-md`}
                          onChange={e => {
                            const status = e.target.value;
                            onChange(status);
                            fieldWiseControlStore.updateFieldWiseControl({
                              ...fieldWiseControlStore.fieldWiseControl,
                              status,
                            });
                          }}
                        >
                          <option>Select</option>
                          {lookupItems(routerStore.lookupItems, 'STATUS').map(
                            (item: any, index: number) => (
                              <option key={index} value={item.code}>
                                {lookupValue(item)}
                              </option>
                            ),
                          )}
                        </select>
                      </Form.InputWrapper>
                    )}
                    name='status'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputDateTime
                        label='Date Creation'
                        placeholder={
                          errors.dateCreation
                            ? 'Please Enter Date Creation'
                            : 'Date Creation'
                        }
                        hasError={!!errors.dateCreation}
                        value={value}
                        disabled={true}
                      />
                    )}
                    name='createdAt'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Version'
                        placeholder={
                          errors.version ? 'Please Enter Version' : 'Version'
                        }
                        hasError={!!errors.version}
                        value={value}
                        disabled={true}
                      />
                    )}
                    name='version'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                </List>
              </Grid>
            ) : (
              <>
                {arrImportRecords?.length > 0 ? (
                  <StaticInputTable data={arrImportRecords} />
                ) : (
                  <ImportFile
                    onClick={file => {
                      handleFileUpload(file[0]);
                    }}
                  />
                )}
              </>
            )}
            <br />
            <List direction='row' space={3} align='center'>
              <Buttons.Button
                size='medium'
                type='solid'
                icon={Svg.Save}
                onClick={handleSubmit(onSubmitFieldWiseControl)}
              >
                Save
              </Buttons.Button>
              <Buttons.Button
                size='medium'
                type='outline'
                icon={Svg.Remove}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </Buttons.Button>
            </List>
          </div>
          <div className='p-2 rounded-lg shadow-xl'>{tableView}</div>
          <ModalConfirm
            {...modalConfirm}
            click={(action?: string) => {
              setModalConfirm({ show: false });
              switch (action) {
                case 'delete': {
                  fieldWiseControlStore.fieldWiseControlService
                    .delete({
                      input: { id: modalConfirm.id },
                    })
                    .then((res: any) => {
                      if (res.removeFieldWiseControl.success) {
                        Toast.success({
                          message: `😊 ${res.removeFieldWiseControl.message}`,
                        });
                        fieldWiseControlStore.fieldWiseControlService.list();
                      }
                    });
                  break;
                }
                case 'updateFields': {
                  fieldWiseControlStore.fieldWiseControlService
                    .update({
                      input: {
                        ...modalConfirm.data.fields,
                        _id: modalConfirm.data.id,
                      },
                    })
                    .then((res: any) => {
                      setModalConfirm({ show: false });
                      if (res.updateFieldWiseControl.success) {
                        Toast.success({
                          message: `😊 ${res.updateFieldWiseControl.message}`,
                        });
                        fieldWiseControlStore.fieldWiseControlService.list();
                      }
                    });
                  break;
                }
              }
            }}
            onClose={() => setModalConfirm({ show: false })}
          />
        </div>
      </>
    );
  }),
);

export default FieldWiseControl;
