import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Buttons, Form, List, Grid, Svg, Icons } from '@/library/components';
import { useStores } from '@/stores';

// utils
import { getFields } from '@/core-utils';

interface ModalFieldsUpdateProps {
  visible: boolean;
  fields: any;
  screen: any;
  onUpdate: (items: any) => void;
  onClose: () => void;
}

export const ModalFieldsUpdate = observer(
  ({
    visible = false,
    fields,
    screen,
    onUpdate,
    onClose,
  }: ModalFieldsUpdateProps) => {
    const {
      loginStore,
      roleStore,
      fieldWiseControlStore,
      routerStore,
      companyStore,
    } = useStores();
    const [fieldsInput, setFieldsInput] = useState<
      Partial<{
        field: string;
        fieldValue: string;
        label: string;
        id: string;
      }>
    >({});
    const [fieldsList, setFieldsList] = useState<any>([]);
    const [arrFieldsInputList, setArrFieldsInputList] = useState<any>();

    useEffect(() => {
      setFieldsList(fields);
      if (screen) {
        const list = getFields(`${screen.name}-${screen.children.name}`);
        setArrFieldsInputList(Object.entries(list));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, screen]);

    return (
      <div>
        {visible && (
          <>
            <div className='justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-start justify-between p-3 border-b border-solid border-gray-300 rounded-t'>
                    <h3 className='text-3xl font-semibold'>
                      {'Update Details'}
                    </h3>
                    <button
                      className='p-1  border-0 text-black opacity-1 ml-6 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                      onClick={() => {
                        onClose && onClose();
                      }}
                    >
                      <span className=' text-black h-6 w-6 text-2xl block outline-none focus:outline-none'>
                        ×
                      </span>
                    </button>
                  </div>
                  <div className='flex flex-col p-2 w-full '>
                    <Grid cols={3}>
                      <>
                        <select
                          className='leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base  border-2 border-gray-300 rounded-md'
                          onChange={e => {
                            const field = e.target.value;
                            setFieldsInput({
                              ...fieldsInput,
                              field,
                            });
                          }}
                        >
                          <option>Select</option>
                          {arrFieldsInputList?.map(
                            (item: any, index: number) => (
                              <option key={index} value={item.join(' - ')}>
                                {item.join(' - ')}
                              </option>
                            ),
                          )}
                        </select>
                      </>

                      <Form.Input
                        placeholder='Field Value'
                        value={fieldsInput?.fieldValue || ''}
                        onChange={fieldValue => {
                          setFieldsInput({
                            ...fieldsInput,
                            fieldValue,
                          });
                        }}
                      />

                      <Form.Input
                        placeholder='Label'
                        value={fieldsInput?.label || ''}
                        onChange={label => {
                          setFieldsInput({
                            ...fieldsInput,
                            label,
                          });
                        }}
                      />

                      <Form.Input
                        placeholder='Id'
                        value={fieldsInput?.id || ''}
                        onChange={id => {
                          setFieldsInput({
                            ...fieldsInput,
                            id,
                          });
                        }}
                      />

                      <Buttons.Button
                        size='medium'
                        type='solid'
                        onClick={() => {
                          let arrValue = fieldsList || [];
                          if (Object.keys(fieldsInput)?.length == 0)
                            return alert('Please enter correct values.');
                          arrValue?.length > 0
                            ? arrValue.push(fieldsInput as any)
                            : (arrValue = [fieldsInput as any]);
                          setFieldsList(arrValue);
                          setFieldsInput({});
                        }}
                      >
                        <Icons.EvaIcon icon='plus-circle-outline' />
                        {'Add'}
                      </Buttons.Button>
                    </Grid>
                    <List space={2} direction='row' justify='center'>
                      <div className='mt-2'>
                        {fieldsList?.map((item, index) => (
                          <div className='mb-2' key={index}>
                            <Buttons.Button
                              size='medium'
                              type='solid'
                              icon={Svg.Remove}
                              onClick={() => {
                                const firstArr =
                                  fieldsList?.slice(0, index) || [];
                                const secondArr =
                                  fieldsList?.slice(index + 1) || [];
                                const finalArray = [...firstArr, ...secondArr];
                                setFieldsList(finalArray);
                              }}
                            >
                              {`${item.field} - ${item.fieldValue} - ${item.label}- ${item.id}`}
                            </Buttons.Button>
                          </div>
                        ))}
                      </div>
                    </List>
                  </div>
                  <div className='flex items-center justify-end  border-t border-solid border-gray-300 rounded-b p-2'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1'
                      type='button'
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => {
                        onClose && onClose();
                      }}
                    >
                      No
                    </button>
                    <button
                      className='bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1'
                      type='button'
                      style={{ transition: 'all .15s ease' }}
                      onClick={() => {
                        onUpdate(fieldsList);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    );
  },
);
