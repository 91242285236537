import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import {
  Form,
  Tooltip,
  Icons,
  Toast,
  AutoCompleteFilterMutiSelectMultiFieldsDisplay,
} from '@/library/components';
import { observer } from 'mobx-react';
import { FormHelper } from '@/helper';
import { useStores } from '@/stores';
import { useForm } from 'react-hook-form';
import { ModalDocxContent } from '@/core-components';
import { FaSearch, FaWordpressSimple } from 'react-icons/fa';
import { ModalDescriptionModify } from './modal-description-modify';
interface DisplayResultProps {
  row: any;
  rowIndex: number;
  isDisable?: boolean;
  rowData: any[];
  isTestName?: boolean;
  onSelect?: (item) => void;
}

interface ModalModifyState {
  show: boolean;
  isViewMode: boolean;
  row?: RowData;
}

interface RowData {
  _id: string;
  result: string | { result?: string };
}

export const DisplayResult = observer(
  ({
    row,
    rowIndex,
    isDisable = false,
    rowData,
    isTestName = true,
    onSelect,
  }: DisplayResultProps) => {
    const { possibleResultsStore, libraryStore, generalResultEntryStore } =
      useStores();
    const { control } = useForm();
    const [conclusionResult, setConclusionResult] = useState<Array<any>>();
    const [libraryList, setLibraryList] = useState<Array<any>>();
    const [modelDescriptionModify, setModelDescriptionModify] =
      useState<ModalModifyState>({
        show: false,
        isViewMode: false,
        row: {
          _id: '',
          result: '',
        },
      });
    const [modalDocxContent, setModalDocxContent] = useState<any>({
      visible: false,
    });

    const focusNextField = (startIndex: number) => {
      let nextIndex = startIndex;
      while (nextIndex < rowData?.length) {
        const nextField: any = document.querySelector(
          `[name=field-${nextIndex}]`,
        );
        if (nextField) {
          nextField.focus();
          break;
        }
        nextIndex++;
      }
    };

    const focusPreviousField = (startIndex: number) => {
      let previousIndex = startIndex;
      while (previousIndex >= 0) {
        const previousField: any = document.querySelector(
          `[name=field-${previousIndex}]`,
        );
        if (previousField) {
          previousField.focus();
          break;
        }
        previousIndex--;
      }
    };

    useEffect(() => {
      switch (row?.resultType) {
        case 'D': {
          possibleResultsStore.possibleResultsService
            .findByFields({
              input: {
                filter: {
                  analyteCode: row?.analyteCode,
                },
              },
            })
            .then(res => {
              if (res.findByFieldsPossibleResult.success) {
                setConclusionResult(
                  res.findByFieldsPossibleResult?.data[0]?.conclusionResult,
                );
              } else {
                Toast.warning({
                  message: `😔 ${res.findByFieldsPossibleResult.message}`,
                });
              }
            });
          break;
        }
        case 'L':
        case 'M': {
          libraryStore.libraryService
            .findByFields({
              input: {
                filter: {
                  libraryType: 'Alpha',
                  groups: 'Results',
                  lab: row?.pLab,
                  department: row?.departement,
                },
              },
            })
            .then(res => {
              if (res.findByFieldsLibrarys.success) {
                setLibraryList(res.findByFieldsLibrarys?.data);
              }
            });
          break;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row]);

    // console.log({
    //   row: toJS(row),
    //   value: toJS(row?.result),
    //   rowIndex,
    //   rowData,
    // });

    return (
      <div className='relative w-full '>
        {row?.resultType === 'V' && (
          <>
            <Form.Input1
              key={rowIndex?.toString()}
              name={`field-${rowIndex}`}
              isAutoFocus={rowIndex === 0}
              type='text'
              disabled={isDisable || row?.calculationFlag}
              placeholder='Result'
              defaultValue={
                typeof row?.result === 'object'
                  ? row?.result?.result?.toString()
                  : row?.result?.toString() || ''
              }
              maxLength={50}
              pattern={FormHelper.patterns.decimalPatterm}
              className='w-full leading-4 p-2 h-7 focus:outline-none focus:ring block shadow-sm sm:text-base border-2 rounded-md'
              onKeyDown={e => {
                switch (e.key) {
                  case 'Enter':
                  case 'ArrowDown':
                  case 'Tab': {
                    e.preventDefault();
                    focusNextField(rowIndex + 1);
                    break;
                  }
                  case 'ArrowUp': {
                    e.preventDefault();
                    focusPreviousField(rowIndex - 1);
                    break;
                  }
                  default:
                    break;
                }
              }}
              onBlur={e => {
                const result = e.target.value;
                if (result) {
                  onSelect &&
                    onSelect({
                      result: Number.parseFloat(result).toFixed(
                        row?.picture || 0,
                      ),
                      numeric: result,
                    });
                }
              }}
            />
          </>
        )}

        {/* {row.resultType === 'D' && !row?.result ? (
          <>
            <select
              name={`field-${row._id}`}
              className={
                'leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2  rounded-md'
              }
              onChange={e => {
                const defaultItem = JSON.parse(e.target.value);
                if (defaultItem) {
                  onSelect &&
                    onSelect({
                      result: defaultItem.possibleValue,
                      alpha: defaultItem.result,
                      abnFlag: defaultItem.abNormal,
                      critical: defaultItem.critical,
                    });
                }
              }}
            >
              <option>Select</option>
              {conclusionResult?.map((item: any, index: number) => (
                <option key={index} value={JSON.stringify(item)}>
                  {`Result: ${item.result} ,
              PossibleValue: ${item.possibleValue} ,
              Ab Normal: ${
                item.abNormal ? (item.abNormal ? 'Yes' : 'No') : 'No'
              } ,
              Critical: ${
                item.critical ? (item.critical ? 'Yes' : 'No') : 'No'
              }`}
                </option>
              ))}
            </select>
          </>
        ) : (
          <span>
            {typeof row?.result === 'object'
              ? row?.result?.result
                  ?.toString()
                  ?.split('\n')
                  .map((str, index) => <p key={index}>{str}</p>)
              : row?.result
                  ?.toString()
                  ?.split('\n')
                  .map((str, index) => <p key={index}>{str}</p>) || ''}
          </span>
        )} */}
        {row.resultType === 'D' && !row?.result && (
          <>
            <select
              name={`field-${row._id}`}
              className={
                'leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2  rounded-md'
              }
              onChange={e => {
                const defaultItem = JSON.parse(e.target.value);
                if (defaultItem) {
                  onSelect &&
                    onSelect({
                      result: defaultItem.possibleValue,
                      alpha: defaultItem.result,
                      abnFlag: defaultItem.abNormal,
                      critical: defaultItem.critical,
                    });
                }
              }}
            >
              <option>Select</option>
              {conclusionResult?.map((item: any, index: number) => (
                <option key={index} value={JSON.stringify(item)}>
                  {`Result: ${item.result} ,
              PossibleValue: ${item.possibleValue} ,
              Ab Normal: ${
                item.abNormal ? (item.abNormal ? 'Yes' : 'No') : 'No'
              } ,
              Critical: ${
                item.critical ? (item.critical ? 'Yes' : 'No') : 'No'
              }`}
                </option>
              ))}
            </select>
          </>
        )}

        {/* {row.resultType === 'L' && !row?.result ? (
          <select
            name={`field-${row._id}`}
            className={
              'leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2  rounded-md'
            }
            onChange={e => {
              const item = JSON.parse(e.target.value);
              if (item) {
                onSelect &&
                  onSelect({
                    result: item.libraryCode + ' - ' + item.description,
                    alpha: item?.libraryCode,
                    abnFlag: item?.abNormal || false,
                    critical: item?.critical || false,
                  });
              }
            }}
          >
            <option>Select</option>
            {libraryList?.map((item: any, index: number) => (
              <option key={index} value={JSON.stringify(item)}>
                {`${item.libraryCode} - ${item.description}`}
              </option>
            ))}
          </select>
        ) : (
          <span>
            {typeof row?.result === 'object'
              ? row?.result?.result
                  ?.toString()
                  ?.split('\n')
                  .map((str, index) => <p key={index}>{str}</p>)
              : row?.result
                  ?.toString()
                  ?.split('\n')
                  .map((str, index) => <p key={index}>{str}</p>) || ''}
          </span>
        )} */}
        {row.resultType === 'L' && !row?.result && (
          <select
            name={`field-${row._id}`}
            className={
              'leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2  rounded-md'
            }
            onChange={e => {
              const item = JSON.parse(e.target.value);
              if (item) {
                onSelect &&
                  onSelect({
                    result: item.libraryCode + ' - ' + item.description,
                    alpha: item?.libraryCode,
                    abnFlag: item?.abNormal || false,
                    critical: item?.critical || false,
                  });
              }
            }}
          >
            <option>Select</option>
            {libraryList?.map((item: any, index: number) => (
              <option key={index} value={JSON.stringify(item)}>
                {`${item.libraryCode} - ${item.description}`}
              </option>
            ))}
          </select>
        )}

        {row.resultType === 'F' &&
          (!row?.result ? (
            <Tooltip tooltipText='Enter Result'>
              <Icons.IconContext
                color='#000000'
                size='20'
                onClick={() =>
                  setModelDescriptionModify({
                    show: true,
                    row,
                    isViewMode: false,
                  })
                }
              >
                {Icons.getIconTag(Icons.IconBi.BiEdit)}
              </Icons.IconContext>
            </Tooltip>
          ) : (
            <Tooltip tooltipText='View Result'>
              <Icons.IconContext
                color='#000000'
                size='20'
                onClick={() =>
                  setModelDescriptionModify({
                    show: true,
                    row,
                    isViewMode: true,
                  })
                }
              >
                <FaSearch size={18} />
              </Icons.IconContext>
            </Tooltip>
          ))}

        <ModalDescriptionModify
          rowValue={
            typeof row?.result === 'object'
              ? row?.result?.result?.toString()
              : row?.result?.toString() || ''
          }
          show={modelDescriptionModify.show}
          isViewMode={modelDescriptionModify.isViewMode}
          onSelect={item => {
            if (onSelect) {
              onSelect({
                result: item,
                alpha: `F - ${row._id}`,
              });
            }
          }}
          onClose={() =>
            setModelDescriptionModify({
              show: false,
              row: {
                _id: '',
                result: '',
              },
              isViewMode: false,
            })
          }
        />

        {/* {row.resultType === 'M' && !row?.result ? (
          <AutoCompleteFilterMutiSelectMultiFieldsDisplay
            loader={false}
            name={`field-${row._id}`}
            placeholder='Search by code'
            data={{
              list: libraryList || [],
              selected: generalResultEntryStore.selectedItems?.library,
              displayKey: ['code', 'description'],
            }}
            onUpdate={item => {
              const items = generalResultEntryStore.selectedItems?.library;
              if (items) {
                onSelect &&
                  onSelect({
                    result: JSON.stringify(
                      _.map(items, o => _.pick(o, ['code'])),
                    ),
                    alpha: `M - ${row._id}`,
                  });
              }
            }}
            onBlur={e => {
              // Handle onBlur if needed
            }}
            onFilter={(value: string) => {
              // Handle onFilter if needed
            }}
            onSelect={item => {
              let library = generalResultEntryStore.selectedItems?.library;
              if (!item.selected) {
                if (library && library.length > 0) {
                  library.push(item);
                } else library = [item];
              } else {
                library = library?.filter(items => {
                  return items._id !== item._id;
                });
              }
              generalResultEntryStore.updateSelectedItems({
                ...generalResultEntryStore.selectedItems,
                library,
              });
            }}
          />
        ) : (
          <ul>
            {row?.result
              ? JSON.parse(
                  typeof row?.result === 'object'
                    ? row?.result?.result?.toString()
                    : row?.result?.toString() || '',
                )?.map((item: any, index: number) => (
                  <li key={index}>{item?.code}</li>
                ))
              : null}
          </ul>
        )} */}

        {row.resultType === 'M' && !row?.result && (
          <AutoCompleteFilterMutiSelectMultiFieldsDisplay
            loader={false}
            name={`field-${row._id}`}
            placeholder='Search by code'
            data={{
              list: libraryList || [],
              selected: generalResultEntryStore.selectedItems?.library,
              displayKey: ['code', 'description'],
            }}
            onUpdate={item => {
              const items = generalResultEntryStore.selectedItems?.library;
              if (items) {
                onSelect &&
                  onSelect({
                    result: JSON.stringify(
                      _.map(items, o => _.pick(o, ['code'])),
                    ),
                    alpha: `M - ${row._id}`,
                  });
              }
            }}
            onBlur={e => {
              // Handle onBlur if needed
            }}
            onFilter={(value: string) => {
              // Handle onFilter if needed
            }}
            onSelect={item => {
              let library = generalResultEntryStore.selectedItems?.library;
              if (!item.selected) {
                if (library && library.length > 0) {
                  library.push(item);
                } else library = [item];
              } else {
                library = library?.filter(items => {
                  return items._id !== item._id;
                });
              }
              generalResultEntryStore.updateSelectedItems({
                ...generalResultEntryStore.selectedItems,
                library,
              });
            }}
          />
        )}

        {(row?.resultType === 'FR' || row?.resultType === 'BO') &&
          !row.result && (
            <Form.InputFile
              label='File'
              name={`field-${row._id}`}
              placeholder='File'
              accept={row?.resultType === 'BO' ? '.pdf' : '.png,.jpg'}
              onChange={e => {
                const file = e.target.files[0];
                if (file) {
                  onSelect &&
                    onSelect({
                      file,
                    });
                }
              }}
            />
          )}

        {(row?.resultType === 'FR' || row?.resultType === 'BO') &&
          row?.result && (
            <div className='flex'>
              <Tooltip tooltipText='Report'>
                <Icons.IconContext
                  size='20'
                  color='#000000'
                  onClick={() => {
                    window.open(
                      typeof row?.result === 'object'
                        ? row?.result?.result?.toString()
                        : row?.result?.toString() || '',
                      '_blank',
                    );
                  }}
                >
                  {Icons.getIconTag(Icons.IconBs.BsFilePdf)}
                </Icons.IconContext>
                <span className='w-12'>
                  {typeof row?.result === 'string' &&
                    row?.result
                      ?.split('/')
                      .pop()
                      .slice(11, row?.result?.split('/').pop()?.length)}
                </span>
              </Tooltip>
            </div>
          )}

        {row?.resultType === 'W' && (
          <>
            <Tooltip tooltipText='Expand result'>
              <FaWordpressSimple
                size='30'
                onClick={() => {
                  setModalDocxContent({
                    visible: true,
                    isTestName: isTestName,
                    testName: row?.testName,
                    details:
                      typeof row?.result === 'object'
                        ? row?.result?.result?.toString()
                        : row?.result?.toString() || '',
                    department: row?.departement,
                    isEditable: row?.panelStatus === 'P',
                    isLibraryImport: row?.panelStatus === 'P',
                    _id: row?._id,
                  });
                }}
              />
            </Tooltip>
            <ModalDocxContent
              {...modalDocxContent}
              onUpdate={details => {
                setModalDocxContent({ visible: false });
                onSelect &&
                  onSelect({
                    result: details,
                    alpha: `W - ${row._id}`,
                  });
              }}
              onClose={() => {
                setModalDocxContent({ visible: false });
              }}
            />
          </>
        )}
      </div>
    );
  },
);
