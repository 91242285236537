/* eslint-disable  */
import React, { useState, useEffect, useRef } from 'react';
interface MultiSelectProps {
  options: Array<string>;
  selectedItems?: Array<string>;
  hasError?: boolean;
  onSelect: (item: any) => any;
}

export const MultiSelect = ({
  options = [],
  selectedItems = [],
  hasError = false,
  onSelect,
}: MultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [isListOpen, setIsListOpen] = useState(false);
  const useOutsideAlerter = ref => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && isListOpen) {
          setIsListOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, isListOpen]);
  };

  useEffect(() => {
    setSelectedOptions(selectedItems);
  }, [selectedItems]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <>
      <div
        className={`flex flex-col w-full rounded-md border-2 ${
          hasError ? 'border-red-500' : 'border-gray-300'
        } dark:bg-boxdark dark:text-white`}
        ref={wrapperRef}
      >
        {/* Dropdown toggle */}
        <span
          className='p-2 mt-1 shadow-sm cursor-pointer select-none'
          onClick={() => setIsListOpen(!isListOpen)}
        >
          {selectedOptions?.length > 0 ? selectedOptions.join(', ') : 'Select'}
        </span>

        {/* Dropdown content */}
        <div
          className={`flex flex-col mx-2 transition-all duration-200 ease-in-out ${
            isListOpen ? 'block' : 'hidden'
          }`}
        >
          {options && options.length > 0 && (
            <ul className='space-y-1'>
              {options.map((item: string, index) => (
                <li
                  key={index}
                  className='flex flex-row items-center space-x-2 p-1'
                >
                  <input
                    className='w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500'
                    type='checkbox'
                    id={`checkbox-${index}`}
                    checked={selectedOptions.includes(item)}
                    onChange={() => {
                      if (selectedOptions.includes(item)) {
                        setSelectedOptions(
                          selectedOptions.filter(e => e !== item),
                        );
                      } else {
                        setSelectedOptions([...selectedOptions, item]);
                      }
                    }}
                    onBlur={() => {
                      if (!isListOpen) onSelect(selectedOptions);
                    }}
                  />
                  <span
                    className='text-gray-900 dark:text-white text-sm cursor-pointer'
                    onClick={() =>
                      document.getElementById(`checkbox-${index}`)?.click()
                    }
                  >
                    {item}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
