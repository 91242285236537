import React, { useEffect } from 'react';
import { useStores } from '@/stores';
import dayjs from 'dayjs';

interface PatientTraceabilityModalProps {
  isOpen: boolean;
  onClose: () => void;
  pId?: number;
  documents?: string;
  labId?: number;
}

const PatientTraceabilityModal: React.FC<PatientTraceabilityModalProps> = ({
  isOpen,
  onClose,
  pId,
  documents,
  labId,
}) => {
  const { eventLogsStore } = useStores();

  useEffect(() => {
    eventLogsStore.eventLogsService.listEventLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOpen) return null;

  const headers = [
    { key: 'pId', label: 'PId' },
    { key: 'labId', label: 'Lab Id' },
    { key: 'rLab', label: 'RLab' },
    { key: 'sampleId', label: 'Sample Id' },
    { key: 'department', label: 'Department' },
    { key: 'panelCode', label: 'Panel Code' },
    { key: 'testCode', label: 'Test Code' },
    { key: 'analyteCode', label: 'Analyte Code' },
    { key: 'event', label: 'Event' },
    { key: 'eventOn', label: 'Event On' },
    { key: 'oldValue', label: 'Old Value' },
    { key: 'newValue', label: 'New Value' },
    { key: 'deleteValue', label: 'Delete Value' },
    { key: 'eventDate', label: 'Event Date' },
    { key: 'eventBy', label: 'Event By' },
    { key: 'comments', label: 'Comments' },
  ];

  const filteredData = eventLogsStore.eventLogsList?.filter(log => {
    const matchesPId = pId ? log.pId === pId : true; // Optional check for pId
    const matchesDocuments = documents
      ? log?.documents?.includes(documents)
      : true;

    return matchesPId && matchesDocuments;
  });

  return (
    <div>
      {/* Modal Background Overlay */}
      <div
        className='fixed inset-0 bg-[#00000024] transition-opacity'
        style={{ zIndex: 9998 }}
      ></div>
      {/* Modal Container */}
      <div className='fixed inset-0 z-[9999] overflow-y-auto'>
        <div className='flex items-center justify-center w-full min-h-screen p-4'>
          {/* Modal Box */}
          <div className='bg-white rounded-lg shadow-xl w-full max-w-7xl p-4 relative'>
            {/* Modal Header */}
            <div className='flex justify-between items-center pb-2 border-b border-gray-200'>
              <h2 className='text-xl font-semibold text-gray-800'>
                Patient Traceability
              </h2>
              <button
                className='text-gray-400 hover:text-gray-600'
                onClick={onClose}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth='2'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className='mt-4 overflow-auto'>
              <table className='table-auto border-collapse w-full text-left'>
                <thead>
                  <tr>
                    {headers.map(header => (
                      <th
                        key={header.key}
                        className='border-b bg-[#6A727F] min-w-[150px] text-white px-4 py-2 font-semibold text-sm sticky top-0 z-10'
                      >
                        {header.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((row, rowIndex) => (
                      <tr key={rowIndex} className='hover:bg-gray-100'>
                        {headers.map(header => {
                          // Retrieve the raw cell value
                          const cellValue = row[header.key] || '';

                          // Determine if eventOn contains "Date"
                          const isEventOnDate =
                            row.eventOn && row.eventOn.includes('Date');

                          let displayValue = cellValue;

                          // If it's an eventDate and is valid, format it
                          if (
                            header.key === 'eventDate' &&
                            dayjs(displayValue).isValid()
                          ) {
                            displayValue = dayjs(displayValue).format(
                              'DD-MM-YYYY hh:mm:ss',
                            );
                          }

                          if (
                            isEventOnDate &&
                            (header.key === 'oldValue' ||
                              header.key === 'newValue') &&
                            dayjs(displayValue).isValid()
                          ) {
                            displayValue = dayjs(displayValue).format(
                              'DD-MM-YYYY hh:mm:ss',
                            );
                          }

                          return (
                            <td
                              key={`${rowIndex}-${header.key}`}
                              className='border-b px-4 py-2 text-sm text-gray-700 min-w-[200px]'
                            >
                              {displayValue}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={headers.length}
                        className='text-center py-4 text-gray-500'
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Modal Footer */}
            <div className='mt-4 flex justify-end'>
              <button
                className='px-3 py-1 bg-gray-500 text-white text-sm rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientTraceabilityModal;
